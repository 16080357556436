import React from "react";

const App = () => {
  let errorMessage = "なし";
  const [inputValue, setInputValue] = React.useState("");
  const [translateMode, setTranslateMode] = React.useState("");
  const handleChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  };

  const isBlank = (text: string) => {
    //空白チェック
    if (text === "") {
      errorMessage = "入力欄が空欄です";
      return true;
    }
    return false;
  };

  const delSpace = (text: string) => {
    //半角spaceは削除する
    text = text.replace(/ /g, "");
    //console.log(text);
    return text;
  };
  const checkError = (text: string) => {
    //空白チェック
    if (text === "") {
      errorMessage = "半角spaceのみが入力されています";
      return true;
    }

    return false;
  };

  const translate = (inputValue: string, mode: string) => {
    if (mode === "ascii文字をtextに変換") {
      return asciiToText(inputValue);
    } else if (mode === "textをascii文字に変換") {
      return textToAscii(inputValue);
    }
  };

  const textToAscii = (text: string) => {
    console.log(text);
    if (isBlank(text)) {
      return "";
    }

    text = delSpace(text);
    if (checkError(text)) {
      return "";
    }

    let ascii = "";
    for (const val of text) {
      //console.log(val);
      ascii += val.charCodeAt(0);
    }
    return ascii;
  };

  const asciiToText = (ascii: string) => {
    if (isBlank(ascii)) {
      return "";
    }

    if (checkError(ascii)) {
      return "";
    }
    let textArray = ascii.split(" ");
    let text = "";
    for (const val of textArray) {
      //console.log(val);
      text += String.fromCharCode(Number(val));
    }

    return text;
  };

  const handleChange: React.ChangeEventHandler<HTMLSelectElement> = (ev) => {
    console.log(ev.target.value);
    setTranslateMode(ev.target.value);
  };

  const style: React.CSSProperties = {
    color: "red",
    display: "inline-block",
  };

  return (
    <div className="App">
      <div id="panelTranslateTextarea">
        <h2>
          変換モード：
          <select onChange={handleChange}>
            <option value="ascii文字をtextに変換">ascii文字をtextに変換</option>
            <option value="textをascii文字に変換">textをascii文字に変換</option>
          </select>
        </h2>
        <textarea rows={4} cols={40} value={inputValue} onChange={(e) => handleChangeText(e)} />

        <textarea readOnly rows={4} cols={40} value={translate(inputValue, translateMode)} />

        <div>
          <div style={style}>リアルタイムエラー</div>：{errorMessage}
        </div>
      </div>
    </div>
  );
};

export default App;
